import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from "../components/SectionTitle";
import Grid from '@material-ui/core/Grid';

export default function AboutUs(props) {

    const useStyles = makeStyles((_) => ({
        text: {
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "300"
        },
        container: {
            padding: "20px 0 40px 0",
            backgroundColor: "#FFFFFF",
        },
        aboutUs: {
            alignItems: "center"
        }
      }));
      const classes = useStyles();

    return (
        <>
            <SectionTitle title="Nosotros"/>
            <Grid className={classes.container} container justifyContent="space-evenly" alignItems="center" item md={12}>
                <Grid item className={classes.aboutUs} md={6}>
                    <p className={classes.text}>Suites Venezia está ubicado en el Malecon de Mazatlán. Ofrecemos alojamiento con piscina al aire libre con vista al mar, suites con balcón privado y vista al mar, aparcamiento privado gratuito, jardín y terraza, Wi-Fi gratuito y recepción las 24 horas.</p>
                    <p className={classes.text}>¡Hablamos tu idioma!</p>
                </Grid>
            </Grid>
        </>
    );
}