import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from "../components/SectionTitle";
import IconCircle from "../components/IconCircle";
import Grid from '@material-ui/core/Grid';

export default function Services(props) {

    const useStyles = makeStyles((_) => ({
        icons: {
          display: "flex",
          justifyContent: 'center',
        },
        container: {
            padding: "20px 0 40px 0",
            backgroundColor: "#7b926c",
        }
      }));
      const classes = useStyles();

    return (
        <>
            <SectionTitle title="Servicios"/>
            <Grid className={classes.container} container justifyContent="space-evenly" alignItems="center" item md={12}>
                <Grid item className={classes.icons} md={2} xs={5}>
                    <IconCircle src="wifi.jpeg" title="Wi-fi"/>
                </Grid>
                <Grid item className={classes.icons} md={2} xs={5}>
                    <IconCircle src="ac.jpeg" title="A/C"/>
                </Grid>
                <Grid item className={classes.icons} md={2} xs={5}>
                    <IconCircle src="pool.jpeg" title="Alberca"/>
                </Grid>
                <Grid item className={classes.icons} md={2} xs={5}>
                    <IconCircle src="tv.jpeg" title="TV"/>
                </Grid>
                <Grid item className={classes.icons} md={2} xs={5}>
                    <IconCircle src="park.jpeg" title="Parking"/>
                </Grid>
            </Grid>
        </>
    );
}