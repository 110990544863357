import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react'
import './navbar.css';

export default function Navbar() {

  const useStyles = makeStyles((_) => ({
    logo: {
      height: "100px",
      width: "auto",
      "@media (max-width: 600px)": {
        height: "50px",
      },
    },
  }));
  const classes = useStyles();

  // adding the states 
  const [isActive, setIsActive] = useState(false);
  //add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };
  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false)
  }
  return (
    <div className="App">
      <header className="App-header">
        <nav className={`navbar`}>
          <img className={classes.logo} src="/logo.jpeg" alt="logo" />
          <ul className={`navMenu ${isActive ? 'active' : ''}`}>
            <li onClick={removeActive}>
              <a href='#Habitaciones' className={`navLink`}>Habitaciones</a>
            </li>
            <li onClick={removeActive}>
              <a href='#Galería' className={`navLink`}>Galería</a>
            </li>
            <li onClick={removeActive}>
              <a href='#Servicios' className={`navLink`}>Servicios</a>
            </li>
            <li onClick={removeActive}>
              <a href='#Contacto' className={`navLink`}>Contacto</a>
            </li>
            <li onClick={removeActive}>
              <a href='#Nosotros' className={`navLink`}>Nosotros</a>
            </li>
          </ul>
          <div className={`hamburger ${isActive ? 'active' : ''}`}  onClick={toggleActiveClass}>
            <span className={`bar`}></span>
            <span className={`bar`}></span>
            <span className={`bar`}></span>
          </div>
        </nav>
      </header>
    </div>
  );
}
