import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from "../components/SectionTitle";
import Grid from '@material-ui/core/Grid';

export default function Footer(props) {

    const useStyles = makeStyles((_) => ({
        logo: {
          height: "200px",
          width: "auto"
        },
        container: {
            backgroundColor: "#7b926c",
            maxHeight: "600px",
        },
        link: {
            textDecoration: "none",
            display: "block",
            fontWeight: "600",
            fontSize: "1.5rem",
            color: "#FFFFFF",
            margin: "0px",
        },
        title: {
            fontWeight: "800",
            fontSize: "2rem",
            color: "#FFFFFF",
            textAlign: "center"
        },
        image: {
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 0px 40px 0px",
            height: "600px",
            alignItems: "center",
            "@media (max-width: 960px)": {
                height: "auto",
            }
        },
        map: {
            backgroundColor: "#7b926c",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px 0px 40px 0px",
            alignItems: "center",
        },
        info: {
            backgroundColor: "#7b926c",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px 0px 40px 0px",
            alignItems: "center",
        },
      }));
      const classes = useStyles();

    return (
        <Grid className={classes.container} container justifyContent="space-evenly" alignItems="center" item md={12}>
            <Grid item className={classes.image} md={3} xs={12}>
                <img className={classes.logo} src="/logo.jpeg" alt="logo" />
            </Grid>
            <Grid item className={classes.map} md={5} xs={12}>
                <p className={classes.title}>Mapa del sitio:</p>
                <a className={classes.link} href='#'>Inicio</a>
                <a className={classes.link} href='#Habitaciones'>Habitaciones</a>
                <a className={classes.link} href='#Galería'>Galería</a>
                <a className={classes.link} href='#Servicios'>Servicios</a>
                <a className={classes.link} href='#Contacto'>Contacto</a>
                <a className={classes.link} href='#Nosotros'>Nosotros</a>
            </Grid>
            <Grid item className={classes.info} md={4} xs={12}>
                <p className={classes.title}>Vísitanos en:</p>
                <p className={classes.link}>Av. del Mar 616, Tellería, 82017</p>
                <p className={classes.link}>Mazatlán, Sinaloa</p>
                <p className={classes.title}>Llámanos</p>
                <p className={classes.link}>+52 669 981 5226</p>
                <p className={classes.link}>+52 669 981 5450</p>
                <p className={classes.link}>+52 669 981 5451</p>
                <p className={classes.title}>O contáctanos por <strong>Whatsapp</strong></p>
                <p className={classes.link}>+52 669 290 3550</p>
            </Grid>
        </Grid>
    );
}