import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from "react-multi-carousel";
import Grid from '@material-ui/core/Grid';
import "react-multi-carousel/lib/styles.css";

export function GalleryCarousel({children, options}) {

    const useStyles = makeStyles((theme) => ({
        carousel: {
            margin: "30px 0"
        },
        image: {
          margin: "0px 20px",
          width: 'auto',
          "@media (max-width: 3000px)": {
            height: "600px",
          },
          "@media (max-width: 1300px)": {
            height: "450px",
          },
          "@media (max-width: 600px)": {
            height: "auto",
            width: "100%",
          },
        },
    }));
    const classes = useStyles();

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    const images = [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
      '9.jpg',
      '10.jpg',
      '11.jpg',
      '12.jpg',
      '13.jpg',
      '14.jpg',
      '15.jpg',
    ]

    return (
        <Carousel 
            responsive={responsive}
            infinite={true}
            containerClass="carousel-container"
            className={classes.carousel}
        >
            {images.map((image, index) => 
              <Grid key={"gallery" + index} container justifyContent="center" alignItems="center" item md={12}>
                <Grid justifyContent="center" alignItems="center" container>
                  <img className={classes.image} src={image} alt="room" />
                </Grid>
              </ Grid>
            )}
            
        </Carousel>
    );
}
