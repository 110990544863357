import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from "../components/SectionTitle";
import { GalleryCarousel } from './GalleryCarousel';

export default function Gallery(props) {

    const useStyles = makeStyles((_) => ({
        carousel: {
            margin: "20px 0",
        }
      }));
      const classes = useStyles();

    return (
        <>
            <SectionTitle title="Galería"/>
            <GalleryCarousel />
        </>
    );
}