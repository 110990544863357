import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RoomsSlide from '../components/RoomsSlide';

export function RoomsCarousel({children, options}) {

    const useStyles = makeStyles((theme) => ({
        carousel: {
            margin: "30px 0"
        },
    }));
    const classes = useStyles();

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return (
        <Carousel 
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            containerClass="carousel-container"
            className={classes.carousel}
        >
            <RoomsSlide 
                src="6.jpg" 
                title="Suite con vista al mar"
                desc={["Todas nuestas suites cuentan con dos habitaciones: una recámara principal con una cama king size, baño completo y vestidor; y una segunda recámara con dos camas individuales y/o literas, un baño completo cuarto de lavado, sala, comedor, cocina y balcón privado con vista al mar."]}
            /> 
            <RoomsSlide 
                src="12.jpg" 
                title="Suite sin vista al mar"
                desc={["Todas nuestas suites cuentan con dos habitaciones: una recámara principal con una cama king size, baño completo y vestidor; y una segunda recámara con dos camas individuales y/o literas, un baño completo cuarto de lavado, sala, comedor y cocina."]}
            /> 
            <RoomsSlide 
                src="14.jpg" 
                title="Estándar"
                desc={["Habitación con una cama matrimonial, frente a la playa.", "Todas las habitaciones cuentan con aire acondicionado, pantalla de 42 pulgadas con cable, agua caliente, inernet inalámbrico, estacionamiento y alberca"]}
            /> 
        </Carousel>
    );
}
