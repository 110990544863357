import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function SectionTitle(props) {
    
    const useStyles = makeStyles((theme) => ({
        sectionTitle: {
            backgroundColor: "#7b926c",
            padding: '30px 0'
        },
        title: {
            textAlign: "center",
            color: '#FFFFFF',
            borderBottom: "8px solid #FFFFFF",
            borderTop: "8px solid #FFFFFF",
            fontSize: "2.5rem",
        }
    }));
    const classes = useStyles();

    return (
        <Grid container justifyContent="center" alignItems="center" item xs={12} className={classes.sectionTitle} id={props.title}>
            <Typography  variant="h2" className={classes.title}>
                {props.title.toUpperCase()}
            </Typography>
        </Grid>
    );
}