import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function RoomsSlide(props) {
    
    const useStyles = makeStyles((_) => ({
      title: {
        marginBottom: '10px',
        "@media (max-width: 3000px)": {
          fontSize: "2.5rem",
        },
        "@media (max-width: 1024px)": {
          fontSize: "2rem",
        },
        "@media (max-width: 600px)": {
          fontSize: "1.5rem",
        },
      },
      desc: {
        fontWeight: '300',
        textAlign: 'justify',
        "@media (max-width: 3000px)": {
          fontSize: "2rem",
        },
        "@media (max-width: 1024px)": {
          fontSize: "1.5rem",
        },
        "@media (max-width: 600px)": {
          fontSize: "1.4rem",
        },
      },
      image: {
        height: "50%",
        width: 'auto',
        "@media (max-width: 3000px)": {
          height: "450px",
        },
        "@media (max-width: 1300px)": {
          height: "300px",
        },
        "@media (max-width: 600px)": {
          width: "100%",
          height: "auto"
        },
      },
    }));
    const classes = useStyles();

    return (
        <>
            <Grid container justifyContent="space-evenly" alignItems="center" item md={12}>
                <Grid item md={4} xs={8}>
                  <img className={classes.image} src={props.src} alt="room" />
                </Grid>
                <Grid item md={4} xs={8}>
                  <Typography  variant="h3" className={classes.title}>
                    {props.title}
                  </Typography>
                  {props.desc && props.desc.map((text, index) =>
                    <Typography key={"rooms" + index} variant="h4" className={classes.desc}>
                      {text}
                    </Typography>
                  )}  
                </Grid>
            </Grid>
        </>
    );
}