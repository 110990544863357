import React from 'react';
import Navbar from '../components/NavBar'
import Cover from "../components/Cover"
import Services from './Services'
import AboutUs from './AboutUs'
import Rooms from './Rooms'
import ContactUs from './ContactUs'
import Footer from './Footer'
import ActionButtons from './ActionButtons'
import Gallery from './Gallery'

export default function App() {
  return (
      <>
        <ActionButtons />
        <Navbar />
        <Cover />
        <Rooms />
        <Gallery />
        <Services />
        <ContactUs />
        <AboutUs />
        <Footer />
      </>
    );
        
}