import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from "../components/SectionTitle";
import Grid from '@material-ui/core/Grid';

export default function ContactUs(props) {

    const useStyles = makeStyles((_) => ({
        text: {
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "300"
        },
        container: {
            padding: "20px 0 40px 0",
            backgroundColor: "#FFFFFF",
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            width: "100%",
            height: "600px",
            backgroundColor: "#7b926c",
            gap: "12px",
            justifyContent: "center",
            alignItems: "center",
        },
        label: {
            color: "#FFFFFF",
            fontSize: "2rem",
            weight: "600",
            alignSelf: "baseline",
            marginLeft: "5%",
        },
        input: {
            height: "2rem",
            width: "90%",
            fontSize: "1.5rem"
        },  
        textArea: {
            width: "90%",
            height: "4.5rem",
            fontSize: "1.5rem"
        },  
        btn: {
            color: "#7b926c",
            padding: "15px 20px",
            backgroundColor: "#FFFFFF",
            border: "0 solid black",
            borderRadius: "4px",
            fontSize: "1.5rem",
            marginTop: "20px",
        }
      }));
      const classes = useStyles();

    return (
        <>
            <SectionTitle title="Contacto"/>
            <Grid className={classes.container} container justifyContent="space-evenly" alignItems="center" item md={12}>
                <Grid item md={5} xs={12}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.444410195041!2d-106.4304172850285!3d23.226909884849967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869f5364c73c72f1%3A0xf1a7bc3a20ba6e82!2sHotel%20Suites%20Venezia!5e0!3m2!1ses-419!2smx!4v1623367382484!5m2!1ses-419!2smx" width="100%" height="600" loading="lazy"></iframe>
                </Grid>
                <Grid item md={5} className={classes.form}>
                    <label className={classes.label} htmlFor="name">Nombre</label>
                    <input className={classes.input} id='name' name='name' type='text'></input>
                    <label className={classes.label} htmlFor="email">Correo electrónico</label>
                    <input className={classes.input} id='email' name='email' type='text'></input>
                    <label className={classes.label} htmlFor="phone">Teléfono</label>
                    <input className={classes.input} id='phone' name='phone' type='text'></input>
                    <label className={classes.label} htmlFor="message">Mensaje</label>
                    <textarea className={classes.textArea} id='message' name='message' rows="3"></textarea>
                    <button className={classes.btn} onClick={() => {}}>ENVIAR</button>
                </Grid>
            </Grid>
        </>
    );
}