import { makeStyles } from '@material-ui/core/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ActionButtons(props) {
    
    const useStyles = makeStyles((theme) => ({
        circle: {
            width: '56px',
            height: '56px',
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            justifyContent: 'center',
            zIndex: '10'
        },
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            right: "0.5rem",
            bottom: "0.5rem",
            position: "fixed",
            zIndex: '10'
        },
        icon: {
            color: "#FFFFFF"
        }
    }));
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.circle} style={{backgroundColor: "#8AD826"}} onClick={() => window.open("https://api.whatsapp.com/send?phone=526692836125", '_blank')}>
                <WhatsAppIcon className={classes.icon} fontSize="medium" />
            </div>
            <div className={classes.circle} style={{backgroundColor: "#2684D8"}} onClick={() => window.open("https://www.facebook.com/HotelVeneziaMazatlanOficial/", '_blank')}>
                <FacebookIcon className={classes.icon} fontSize="medium" />
            </div>
            <div className={classes.circle} style={{backgroundColor: "#cb3638"}} onClick={() => window.open("https://www.instagram.com/hotelsuitesvenezia/", '_blank')}>
                <InstagramIcon className={classes.icon} fontSize="medium" />
            </div>
            <div className={classes.circle} style={{backgroundColor: "#262851"}} onClick={() => window.scrollTo({top:0,behavior:'smooth'})}>
                <KeyboardArrowUpIcon className={classes.icon} fontSize="medium" />
            </div>
        </div>
    );
}