import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export default function IconCircle(props) {
    
    const useStyles = makeStyles((theme) => ({
        circle: {
            width: '140px',
            height: '140px',
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginBottom: '1rem',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
        },
        image: {
            heigh: "80px",
            width: "80px",
        },
        title: {
            color: "#FFFFFF",
            textAlign: "center"
        },
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }
    }));
    const classes = useStyles();

    return (
        <div className="container">
            {
                props.title && 
                    <Typography  variant="h4" className={classes.title}>
                        {props.title}
                    </Typography>
            }
            <div className={classes.circle}>
                <img className={classes.image} src={props.src} alt="logo" />
            </div>
        </div>
    );
}